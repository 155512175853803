<!-- 用户电子实验记录本页面 -->
<template>
    <div class="user-notebook-ctn">
        <div class="user-notebook-header">
            {{  userName }}
        </div>
        <a-spin :spinning="loading" tip="正在加载..." style="width: 100%;">
            <div class="user-notebook-content">
                <div class="single-item" v-for="(record,index) in list" :key="index">
                    <SingleProjectItem :record="record"></SingleProjectItem>
                </div>
                <div v-if="list.length === 0" style="width: 100%; display: flex; justify-content: center; align-items: center;  margin: 20px 0 0px 20px; color: rgba(0, 0, 0, 0.85); height: 700px; background: #fff;" >
                    <a-empty ></a-empty>
                </div>
            </div>
        </a-spin>
        <div class="default-pagination" style="padding: 32px 16px 30px 16px;">
			<a-pagination  hideOnSinglePage @change="handlePageChange" v-model="current"
				:pageSize.sync="pageSize" :total="total" :pageSizeOptions="['8' , '12', '24', '48']" showSizeChanger @showSizeChange="handleSizeChange"/>
		</div>
    </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue-demi';
import  SingleProjectItem from './components/singleProjectItem.vue';
import { getGroupNotebooks } from '@/api/researchGroup';

export default {
    props: {
        
    },
    components: {
        SingleProjectItem
    },
    setup(props, context) {
        const { $route, $router } = context.root;
    
        const userName = ref(''); // 用户名

        const userId = ref('');    // 用户ID

        const researchGroupId = ref(''); // 课题组id

        const pageSize = ref(8);    // 单页数量
        
        const current = ref(1); // 当前页数

        const total = ref(0);   // 全部条数

        const loading = ref(true);  // 是否正在加载

        const list = ref([]);    // 项目记录本列表数据

        // 页脚改变事件
        const handlePageChange = () => {
            getList();
        }

        // 每页数量改变
        const handleSizeChange = (newCurrent, newPageSize) => {
            current.value = 1;
            pageSize.value =newPageSize;
            getList()
        }

        // 获取列表
        const getList = async () => {
            loading.value = false;
            const res = await getGroupNotebooks({
                groupId: researchGroupId.value,
                userId: userId.value,
                pageSize: pageSize.value,
                pageNo: current.value
            });
            if(res.success) {
                list.value = res.data.data;
                total.value = res.data.count;
            }
            loading.value = false;
        }
        

        onBeforeMount(() => {
            userName.value = $route.query.name;
            userId.value = $route.query.id;
            researchGroupId.value = $route.query.researchGroupId;
            getList();
        })

        return {
            userName,
            userId,
            pageSize,
            current,
            total,
            handlePageChange,
            loading,
            list,
            handleSizeChange
        }
    }
}
</script>

<style lang="less" scoped>
.user-notebook-ctn {
    margin-bottom:20px;
}
.user-notebook-header {
    padding: 20px 20px 0px 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}
.user-notebook-content {
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
    width: 100%;
    .single-item {
        margin-top: 20px;
        margin-left: 20px;
        //flex: 1;
        width: calc(25% - 20px);
        min-width: 250px;
        //max-width: 25%;
    }
}
</style>
<!-- 电子记录单个项目记录本 -->
<template>
    <div class="project-item-ctn">
        <div class="project-item-content">
            <div class="form-item">
                <div class="form-label">
                    项目名称：
                </div>
                <div class="form-value">
                    {{  record.projectName  }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-label">
                    项目负责人：
                </div>
                <div class="form-value">
                    {{ record.projectHeadName }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-label">
                    电子记录本：
                </div>
                <div class="form-value">
                     {{ record.noteBookCount }}
                </div>
            </div>
            <div v-if="record.noteBooklst && record.noteBooklst.length > 0" class="form-item">
                <div v-for="(notebook, index) in record.noteBooklst"  class="notebook-item">
                    {{  notebook.noteBookName  }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-label">
                    电子实验记录：
                </div>
                <div class="form-value">
                     {{  record.experimentCount  }}
                </div>
            </div>
        </div>
        <div class="project-item-footer">
            <div class="percent-cout">
                <div class="dot" style="background: #1890FF;">

                </div>
                <div class="percent-text">
                    编辑中
                </div>
                <div class="percent-value">
                    {{  (record.experimentEditCount + record.experimentSaveCount) > 0 ? (record.experimentEditCount / (record.experimentEditCount + record.experimentSaveCount) ).toFixed(2) * 100 : 0    }}%
                </div>
            </div>
            <div class="percent-cout">
                <div class="dot" style="background: #52C41A;">

                </div>
                <div class="percent-text">
                    归档成功
                </div>
                <div class="percent-value">
                    {{ (record.experimentEditCount + record.experimentSaveCount) > 0 ?  ( 1- record.experimentEditCount / (record.experimentEditCount + record.experimentSaveCount) ).toFixed(2) * 100 : 0    }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        record: {
            default: () => ({})
        }
    },
    setup(props, context) {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
.project-item-ctn {
    background: #FFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.project-item-content {
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);
    .form-item + .form-item {
        margin-top: 12px;
    }
    .form-item {
        display: flex;
        .form-value {
            color: rgba(0, 0, 0, 0.65);
        }
        .notebook-item {
            background: #F7F8FA;
            color: #1890FF;
            padding: 7px 14px;
            border-radius: 16px;
            opacity: 0.8;
            margin-left: 12px;
            &:hover {
                //opacity: 0.8;
                //text-decoration: underline;
            }
        }

    } 
}
.project-item-footer {
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    .percent-cout {
        display: flex;
        align-items: center;
        .dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
        .percent-text{
            margin-left: 8px;
            color: rgba(0, 0, 0, 0.85);
        }
        .percent-value {
            color: rgba(0, 0, 0, 0.65);
            margin-left: 8px;
        }
    }
    .percent-cout +.percent-cout {
        margin-left: 40px;
    }
}
</style>